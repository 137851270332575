.conditions-container{
    display : flex;
    flex-direction: column;
}

.condition-groups{
    display:flex;
    flex-direction: column;
}

.condition-operator{
    margin-left: 20px;
}

.condition-group {
    flex-grow: 1;
    padding: 0px 10px;
    flex-direction: column;
}

.condition-item-separator{
    display:flex;
    justify-content: center;
    margin: 20px 0px;
}

.condition-field{
    justify-self: flex-start;
}

.condition-item-separator::after{
    margin-left: 30px;
    content:  " ";
    height: 1px;
    flex-grow: 1;
    align-self: center;
    background-color: #1481f2;
}

.condition-item-separator::before{
    margin-right: 30px;
    content:  " ";
    height: 1px;
    flex-grow: 1;
    align-self: center;
    background-color: #1481f2;
}

.condition-group-container {
    border: 1px solid #1481f2;
    border-radius: 10px;
    padding: 15px;
}

.condition-item {
    align-items: center;
    display : flex;
    justify-content: space-between;
}

.condition-value {
    align-items: center;
    display : flex;
}

.condition-value input{
    width : 100px;
    margin-right: 10px;
}

.new-button-container
{
    display:flex;
    justify-content: center;
    margin-bottom: 10px;
}