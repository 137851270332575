.placeholder__content:hover, .signature:hover {
    opacity: 0.5;
    filter: alpha(opacity=50);
    cursor: pointer;
}

.mod-margin-left-4
{
    margin-left:4px;
}

.mod-margin-top-4
{
    margin-top:4px;
}

.mod-margin-bottom-4
{
    margin-bottom:4px;
}

.mod-margin-left-8
{
    margin-left:8px;
}

.mod-margin-top-8
{
    margin-top:8px;
}

.mod-margin-bottom-8
{
    margin-bottom:8px;
}

.popup {
    width: 100%;
}

.mod-font-11
{
font-size: 11px;
}

.send-document-by-email .info__row-name {
    width: 80%;
}

.invoice-info ul {
    padding-top: 10px;
}

.info-button {
    position: absolute;
    left: 95%;
    padding-top: 5px;
    float:left;
}

.other-recepients-placeholder {
    width: 100%;
    float:left;
}

.mod-padding-top-6
{
    padding-top: 6px;
}

.mod-padding-right-6
{
    padding-right: 6px;
}

@keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  .cursor-pointer
  {
      cursor: pointer;
  }

  .react-datepicker__current-month
  {
      text-transform: capitalize;
  }

  .price-list-amount
  {
    color : #a5a5a5;
  }

    
element.style {
    border: 0px;
    padding-left: 32px;
}
.info__row:last-child {
    margin-bottom: 0;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
}
.info__row:last-child {
    margin-bottom: 0;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
}
@media only screen and (min-width: 620px)
{
    .desktop-hide {
        display : none;
    }
}


.Toastify{
    width : 100%;
    position : fixed;
    z-index : 9999;
}

.notification-body{
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.notification {
    -webkit-box-shadow: 20px 20px 20px rgba(0, 0, 0, .4);
    -moz-box-shadow: 20px 20px 20px rgba(0, 0, 0, .4);
    box-shadow: 20px 20px 20px rgba(0, 0, 0, .4);
    padding: 20px 40px;
}

@keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(.3, .3, .3);
    }
  
    50% {
      opacity: 1;
    }
  }
  
  .zoomIn {
    animation-name: zoomIn;
  }

  .zoomOut {
    animation-name: zoomOut;
  }
  
  @keyframes zoomOut {
    from {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
      transform: scale3d(.3, .3, .3);
    }
  
    to {
      opacity: 0;
    }
  }